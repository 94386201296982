import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(MUITheme => ({
  navigationLinks: { 
    marginLeft: 15,  
    [MUITheme.breakpoints.down("sm")]: { display: "block" },
  },
  navlist: {
    padding: 0,
    //margin: 0,
    display: "flex",
    "& li": {
      width: "auto",
      padding: 0,
      marginRight: 40,
      //"&:last-of-type": { marginRight: 0 , marginLeft:40},
    },
    [MUITheme.breakpoints.down("sm")]: { display: "block", alignItems:'center', marginLeft:'0px',  "&:last-of-type":{marginLeft:'0px'}},
  },
  dFlex:{
    display: "flex",
    alignItems:'center'
  },
  navLink: {
    color: MUITheme.palette.text.blue.secondary,
    textDecoration: "none",
    fontFamily: MUITheme.typography.fontFamily.primary,
    fontSize: 18,
    lineHeight: '32px',
    [MUITheme.breakpoints.down("sm")]: { margin:'auto' },   
  },
  navLinkActive: {
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: MUITheme.palette.text.red,
    color: MUITheme.palette.text.bgBlue,
    [MUITheme.breakpoints.down("sm")]: { margin:'auto', border:'none', borderColor: 'none'},
  },
  serviceSelectorContainer: { 
    //marginLeft: 65,
    marginRight: 40,
    position: 'relative',
    display:"inlin-block",
    '& div':{
      color: MUITheme.palette.text.blue.secondary,
      cursor: 'pointer',
      fontFamily: MUITheme.typography.fontFamily.primary,
      fontSize: 18,
      lineHeight: '32px'
    },
    '& active':{ 
      borderBottomColor: MUITheme.palette.text.red,
      borderBottomStyle: 'solid',
      borderBottomWidth: 3,
      color: MUITheme.palette.text.bgBlue
    },
    [MUITheme.breakpoints.down("sm")]: { margin: "auto", border:'none', borderColor:'none'},
  },
  servicOptions:{
    cursor:'pointer',
    border: '1px solid #E2F3FA',
    borderRadius: '6px 0 6px 6px',
    display: 'none',
    // position: 'absolute',
    // top: '132%',
    // zIndex: 1,
    backgroundColor: MUITheme.palette.text.white.primary,
    boxShadow: '8px 16px 48px 0 rgba(0,0,0,0.1)',
    '& a':{    
      boxSizing: 'border-box',
      width: 202,
      padding: '15px 21px',
      display: 'block',
      color: MUITheme.palette.text.primary,
      fontFamily: MUITheme.typography.fontFamily.primary,
      fontSize: 16,
      lineHeight: '22px',
      textDecoration: 'none',
      '&:hover':{ color: MUITheme.palette.text.bgBlue }
    }
  },
  activeMenu: {
    //background: MUITheme.palette.background.lightBlue,
    color: MUITheme.palette.text.bgBlue,
    fontWeight: 600,
    textDecoration: "none",
    padding:"15px 16px",
    fontSize: 16,
    fontFamily: MUITheme.typography.fontFamily.primary
  },

  menuItem:{
    textDecoration:'none',
    color: MUITheme.palette.text.primary,
    fontWeight: 600,
    padding:"15px 16px",
    fontSize: 16,
    fontFamily: MUITheme.typography.fontFamily.primary,
    '& hover':{
      color: MUITheme.palette.text.bgBlue,
    }
  },

  showOptions:{ display: 'block' }
}));
