import { API } from "aws-amplify";

import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";
import { initializeAPIOptions,initializeAPIOptionsWithCId,setPayload } from "../../utility/API";
import { fetchAuthenticatedUser } from "./UserActions/loginActions";

import { errorActions, loaderActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const S_SCHEDULER_METADATA = "SCHEDULER_METADATA";
export const S_SCHEDULER_NOT_CHECKED_IN_PATIENTS = "patientsNotCheckedIn";
export const S_SCHEDULER_PATIENT_APPOINTMENTS = "patientAppointments";
export const S_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR = "patientAppointmentCalendar";
export const S_SCHEDULER_APPOINTMENT_SUMMARY = "appointmentSummary";
export const S_SCHEDULER_OPT_APPOINTMENTS = "optimizedAppointments";
export const S_SCHEDULER_PATIENTS_MOVED_LIST = "patientsMovedList";
export const S_SCHEDULER_DATA_RESET = "SCHEDULER_DATA_RESET";
export const S_SCHEDULER_SETTING_DATA = "settingsData";


const updateErrorCode = err => dispatch => {
    dispatch(errorActions.resetCustomAuthenticationErrorCode(err));
};

const handleException = error => dispatch => {
    const status = error.response?.status
    if (status === 403 || status === 500) {
        dispatch(updateErrorCode(status));
    }
}

const dummyData = {};


const fetchMetadataApi = (selectedClinicId) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
  
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_METADATA;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token);
  
   const data = await API.get(apiName, apiPath, apiOptions);
   //return dummyData[S_SCHEDULER_METADATA]; 
   return data.data;
}

const fetchSettingsDataApi = (selectedClinicId) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

 const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
 const apiPath = APIPaths.SMART_SCHEDULER_SETTINGS_METADATA;
 const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token);

 const data = await API.get(apiName, apiPath, apiOptions);
 //return dummyData[S_SCHEDULER_SETTING_DATA]; 
 return data.data;
}

const fetchPatientAppointmentsApi = (selectedClinicId,filters={}) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_APPOINTMENTS;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,undefined,filters);
   //console.log('fetchPatientAppointmentsApi',apiOptions);
   const data = await API.get(apiName, apiPath, apiOptions);
   //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id)); 
   return data;
}

const fetchPatientAppointmentCalendarApi = (selectedClinicId,filters={}) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());
 const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
 const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR;
 const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,undefined,filters);
 //console.log('fetchPatientAppointmentsApi',apiOptions);
 const data = await API.get(apiName, apiPath, apiOptions);
 //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id)); 
 return data.data;
}

const fetchAppointmentSummaryApi = (selectedClinicId,filters={}) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_APPOINTMENT_SUMMARY;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,undefined,filters);
   //console.log('fetchPatientAppointmentsApi',apiOptions);
   const data = await API.get(apiName, apiPath, apiOptions);
   //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id)); 
   return data.data;
}

const optimizePatientAppointmentsApi = (selectedClinicId,filters={}) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_OPT_PATIENT_APPOINTMENTS;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,undefined,filters);
   //console.log('fetchPatientAppointmentsApi',apiOptions);
   const data = await API.get(apiName, apiPath, apiOptions);
   //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id)); 
   return data;
}

const fetchPatientsMovedListApi = (selectedClinicId,filters) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
   //const qp = location?{location}:undefined;
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_MOVE_LIST;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,undefined,filters);
  
   const data = await API.get(apiName, apiPath, apiOptions);
   return data;
   //return dummyData[S_SCHEDULER_PATIENTS_MOVED_LIST][filters.page];
}

const updateAppointmentStatusApi = (selectedClinicId,body) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
   //const qp = location?{location}:undefined;
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_UPDATE_APPOINTMENT_STATUS;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,body,null);
  
   const data = await API.post(apiName, apiPath, apiOptions);
   return data;
   //return dummyDeleted.push(body.appointmentId); 
}

const updateSettingsApi = (selectedClinicId,body) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
   //const qp = location?{location}:undefined;
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_UPDATE_SETTINGS;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,body,null);
  
   const data = await API.post(apiName, apiPath, apiOptions);
   return data;
   //return dummyDeleted.push(body.appointmentId); 
}

const revertMoveApi = (selectedClinicId,body) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
   //const qp = location?{location}:undefined;
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_REVERT_APPOINTMENT;
   const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token,body,null);
  
   const data = await API.post(apiName, apiPath, apiOptions);
   return data;
   //return dummyDeleted.push(body.moveId); 
}




export const fetchMetadata = (clinicId,loader=true) => async dispatch=>{
    try {
      loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_DETAILS));
        if (clinicId) {
            const response = await dispatch(fetchMetadataApi(clinicId));
            dispatch(setPayload(S_SCHEDULER_METADATA,response));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_METADATA,{}));
        dispatch(handleException(error));
    }
    finally{
      loader && dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchSettingsData = (clinicId,loader=true) => async dispatch=>{
  try {
    loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_SETTINGS));
      if (clinicId) {
          const response = await dispatch(fetchSettingsDataApi(clinicId));
          dispatch(setPayload(S_SCHEDULER_SETTING_DATA,response,S_SCHEDULER_SETTING_DATA));
      }
  } catch (error) {
      dispatch(setPayload(S_SCHEDULER_SETTING_DATA,[],S_SCHEDULER_SETTING_DATA));
      dispatch(handleException(error));
  }
  finally{
      dispatch(loaderActions.stopAppLoader());
  }
}

export const fetchNotCheckedInPatients = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_DETAILS));
        if (clinicId) {
            const response = await dispatch(fetchPatientAppointmentsApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_NOT_CHECKED_IN_PATIENTS,response,S_SCHEDULER_NOT_CHECKED_IN_PATIENTS));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_NOT_CHECKED_IN_PATIENTS,{data:[]},S_SCHEDULER_NOT_CHECKED_IN_PATIENTS));
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchPatientAppointmentCalendar = (clinicId,filters) => async dispatch=>{
  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_APPOINTMENTS));
      if (clinicId) {
          const response = await dispatch(fetchPatientAppointmentCalendarApi(clinicId,filters));
          dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR,response));
      }
  } catch (error) {
      dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR,{}));
      dispatch(handleException(error));
  }
  finally{
      dispatch(loaderActions.stopAppLoader());
  }
}

export const fetchPatientAppointments = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_APPOINTMENTS));
        if (clinicId) {
            const response = await dispatch(fetchPatientAppointmentsApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENTS,response,S_SCHEDULER_PATIENT_APPOINTMENTS));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENTS,{data:[]},S_SCHEDULER_PATIENT_APPOINTMENTS));
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchAppointmentSummary = (clinicId,filters) => async dispatch=>{
    try {
        //dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_APPOINTMENTS));
        if (clinicId) {
            const response = await dispatch(fetchAppointmentSummaryApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_APPOINTMENT_SUMMARY,response,S_SCHEDULER_APPOINTMENT_SUMMARY));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_APPOINTMENT_SUMMARY,{},S_SCHEDULER_APPOINTMENT_SUMMARY));
        dispatch(handleException(error));
    }
    finally{
        //dispatch(loaderActions.stopAppLoader());
    }
}

export const optimizePatientAppointments = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(filters.dryrun?LoaderContent.GET_OPT_PATIENT_APPOINTMENTS:LoaderContent.OPT_PATIENT_APPOINTMENTS));
        if (clinicId) {
            const response = await dispatch(optimizePatientAppointmentsApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_OPT_APPOINTMENTS,filters.dryrun?response:{data:[]},S_SCHEDULER_OPT_APPOINTMENTS));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_OPT_APPOINTMENTS,{data:[]},S_SCHEDULER_OPT_APPOINTMENTS));
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchPatientsMovedList = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_DETAILS));
        if (clinicId) {
            const response = await dispatch(fetchPatientsMovedListApi(clinicId,filters));
            //console.log('fetchPatientsMovedList',response);
            dispatch(setPayload(S_SCHEDULER_PATIENTS_MOVED_LIST,response,S_SCHEDULER_PATIENTS_MOVED_LIST));
        }
    } catch (error) {
        //console.log('fetchPatientsMovedList:Err',error);
        dispatch(setPayload(S_SCHEDULER_PATIENTS_MOVED_LIST,{page_info:{total:0,page:1},data:[]},S_SCHEDULER_PATIENTS_MOVED_LIST));
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const updateAppointmentStatus = (clinicId,payload) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATING_PATIENT_APPOINTMENT_STATUS));
        if (clinicId) {
            const response = await dispatch(updateAppointmentStatusApi(clinicId,payload));
        }
    } catch (error) {
        dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const updateSettings = (clinicId,payload) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATING_SMART_SCHEDULER_SETTINGS));
        if (clinicId) {
            const response = await dispatch(updateSettingsApi(clinicId,payload));
        }
    } catch (error) {
        dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const revertMove = (clinicId,payload) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.REVERT_PATIENT_MOVE));
        if (clinicId) {
            const response = await dispatch(revertMoveApi(clinicId,payload));
            return response;
        }
    } catch (error) {
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const resetData = (properties) => async dispatch=>{
    try {
        dispatch(setPayload(S_SCHEDULER_DATA_RESET,{properties}))
    } catch (error) {
        
    }
}

