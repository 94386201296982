
import {smartSchedulerActions} from '../actionCreators';

const initialState = {
    patientsMovedList:{data:[],initial:true},
    patientsNotCheckedIn:{data:[],initial:true,activeSlots:[],providers:[],updatedOn:new Date()},
    patientAppointments:{data:[],initial:true},
    optimizedAppointments:{data:[],initial:true},
    optimizerReadOnly:false,
    appointmentSummary:{},
    settingsData:{fields:[],selected:{},initial:true},
    facilities:[],
    programs:{},
    appointmentStatus:[],
    appointmentType:{},
    moveTypes:[],
    rejectReasons:[]
}

const smartSchedulerReducer = (state = initialState, action) =>{
    switch(action.type){     
        case smartSchedulerActions.S_SCHEDULER_METADATA:{
            return{
                ...state, 
                ...action.payload,
                appointmentType:(action.payload.PROVIDER_DISCIPLINE_TYPE || []).reduce((d,apt)=>{
                    d[apt.idfier] = apt;
                    return d;
                },{}),
                programs:(action.payload.PROGRAM_TYPE || []).reduce((d,apt)=>{
                    d[apt.idfier] = apt;
                    return d;
                },{}),
                optimizerReadOnly:(action.payload.OPTIMIZER_READ_ONLY || [])[0]?.value == 'true',
                rejectReasons:action.payload.REVERT_REASON || [],
                moveTypes:action.payload.MOVE_TYPE || [],
                facilities:(action.payload.FACILITY || []).map(f=>({...f,id:parseInt(f.idfier)})),
                appointmentStatus:(action.payload.APPOINTMENT_STATUS || []).map(f=>({...f,id:parseInt(f.idfier)}))
            };
        } 
        case smartSchedulerActions.S_SCHEDULER_DATA_RESET:{
            const resetData = action.payload.properties?{}:{...initialState}
            action.payload.properties && action.payload.properties.forEach(k => {
                resetData[k] = initialState[k];
            });
            return {...state,...resetData};
        } 
        case smartSchedulerActions.S_SCHEDULER_NOT_CHECKED_IN_PATIENTS:{
            const activeSlots = action.payload.data.map(d=>d.appointment_start_at)
            return {...state,patientsNotCheckedIn:{...action.payload,activeSlots,initial:false,updatedOn:new Date()}}
        }
        case smartSchedulerActions.S_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR:{
            const {appointments={},providers=[],slots=[]} = action.payload
            const appointmentData = [];
            const activeSlots = [];
            slots.forEach(slot=>{
                const appnts = appointments[slot] || [];
                const hasAppointment = !!appnts.find(a=>a.patient_id);
                hasAppointment && activeSlots.push(slot);
                appointmentData.push({
                    appointment_start_at:slot,
                    hasAppointment,
                    ...Object.groupBy(appnts,({resource_id})=>resource_id)
                })
            })
            return {...state,patientsNotCheckedIn:{data:appointmentData,providers,activeSlots,initial:false,updatedOn:new Date()}}
        }
        case smartSchedulerActions.S_SCHEDULER_SETTING_DATA:{
            const selected = action.payload.reduce((sv,md)=>{
                sv[md.field] = md.user_selection;
                return sv
            },{})
            return {...state,settingsData:{fields:action.payload,selected}}
        }
        case smartSchedulerActions.S_SCHEDULER_APPOINTMENT_SUMMARY:
        case smartSchedulerActions.S_SCHEDULER_OPT_APPOINTMENTS:    
        case smartSchedulerActions.S_SCHEDULER_PATIENTS_MOVED_LIST:
        case smartSchedulerActions.S_SCHEDULER_PATIENT_APPOINTMENTS:if(action.dataKey){
            return {...state,[action.dataKey]:action.payload}
        }        
        default : {
            return state;
        }
    }

}
export default smartSchedulerReducer;