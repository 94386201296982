import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  sessionExpCard:{
    
    maxWidth: 415,
    margin: "200px auto 0",
    textAlign: "center",
    fontFamily:theme.typography.new.fontFamily.primary,
    '& .MuiCardHeader-title':{
      marginTop:60
    },
    "& img":{
      maxHeight:60,
    },
    "& h2": {
      padding: "6px 0",
      color: theme.palette.new.text.black,
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: 0.32,
      lineHeight: "32px",
    },
    "& p": {
      paddingTop: 5,
      color: theme.palette.new.text.black,
      fontFamily:theme.typography.new.fontFamily.primary,
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0.25,
      lineHeight: "20px",
      marginBottom:16
    },
    '& .modalActions':{
      fontFamily:theme.typography.new.fontFamily.primary,
      padding: 24,
      borderTop: '1px solid rgb(190 194 205 / .3)',
      marginTop: 18,
      display: 'flex',
      justifyContent: 'center',
      '& button.button':{
        margin:8,
              fontWeight: 600,
              color:theme.palette.new.text.black,
              border: " 1px solid var(--sm_primary)",
              textTransform:'none',
              '&.primary':{
                  backgroundColor:'var(--sm_primary)',
                  color:'white'
              },
              '&.Mui-disabled':{
                  borderColor: theme.palette.new.border.primary,
                  backgroundColor:'unset',
                  color:'rgba(0, 0, 0, 0.26)',
                  cursor:'not-allowed'
              }
      }
    }
        
  }
}));
