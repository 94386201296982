export const useStyles = MUITheme => ({
  UserProfilePopUpContainer: {
    width: 400,
    borderRadius: 4,
    mrgin: 0,
    position: "absolute",
    top: 10,
    right: 60,
    backgroundColor: MUITheme.palette.background.white,
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.07), 0 2px 1px -1px rgba(0,0,0,0.07), 0 1px 3px 0 rgba(0,0,0,0.1)",
    zIndex: 1,
  },
  userProfileMenuList: {
    padding: 0,
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: MUITheme.palette.border.grey,
    margin: 0,
    "& li": {
      padding: "12px 20px",
      display: "flex",
      cursor: "pointer",
      listStyle: "none",
      '& a':{ 
        display: "flex",
        alignItems:'center',
        width: '100%',
        textDecoration: 'none' 
      },
      "& svg": {
        paddingRight: 15,
        color: MUITheme.palette.text.blue.secondary,
        verticalAlign: "middle",
        width:'unset'
      },
      "& span.text": {
        width: '100%',
        color: MUITheme.palette.text.blue.secondary,
        textDecoration: "none",
        fontFamily: MUITheme.typography.fontFamily.primary,
        fontSize: 18,
        lineHeight: '28px'
      },
    },
  },
});
