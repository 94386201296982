import React,{useContext} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoodIcon from "@material-ui/icons/Mood";
import SettingsIcon from '@material-ui/icons/Settings';
import { Modal } from "@material-ui/core";

import {PermissionContext} from "../../../PermissionContext";
import { useStyles } from "./styles";
import UserProfileCard from "../../../common/UserProfileCard";
import { loginActions } from "../../../../Redux/actionCreators/UserActions";
import {GlobalRoutes} from "../../../../GlobalRouter/Routes";
import SvgIcon from "../../../common/SvgIcon";

const UserProfilePopUp = ({ classes, show, handleClose }) => {

  const history = useHistory();
  const permissions = useContext(PermissionContext);
  const settingPrv = "SERVICE SUBSCRIPTION";
  const { firstName, profilePicURL} = useSelector(state => ({
    firstName: state.userReducer.firstName,
    profilePicURL: state.userReducer.profilePicURL,
  }));
  const dispatch = useDispatch();

  const handleSignout = (e) => {
    e.preventDefault();
    dispatch(loginActions.signout);
    history.push(GlobalRoutes.HOME.path);
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <div className={classes.UserProfilePopUpContainer}>
        <UserProfileCard profilePicURL={profilePicURL} firstName={firstName} />
        <ul className={classes.userProfileMenuList}>
          <li>
            <Link to={GlobalRoutes.USER_PROFILE.path}>
              <SvgIcon icon="/static/images/my-profile.svg" size="normal" className="mr-4"/>
              <span className="text">My Profile</span>
            </Link>
          </li>
          <li>
            <Link to={GlobalRoutes.SETTINGS.path+(permissions.includes(settingPrv)?"":"/helpcenter")}>
              <SvgIcon icon="/static/images/settings.svg" size="normal" className="mr-4"/>
              <span className="text">Settings</span>
            </Link>
          </li>
          <li>
            <a href="#" onClick={handleSignout}>
              <SvgIcon icon="/static/images/logout.svg" size="normal" className="mr-4"/>
              <span className="text">Sign Out</span>
            </a>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default withStyles(useStyles)(UserProfilePopUp);
