import React, { Suspense } from "react";
import { BrowserRouter as ReactRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";

import { setupRouteAuthentications } from "./Routes";
import { loginActions } from "../Redux/actionCreators/UserActions";
import PrivateRoute,{NonLoginRoute} from "../components/PrivateRoute";
import PageNotFound from "../components/common/PageNotFound";

class GlobalRouter extends React.Component {
  componentDidMount() {
    this.props.initApp();
  }

  render() {
    if (!this.props.isInitialized) {
      return <LinearProgress />;
    }

    const routes = setupRouteAuthentications();

    return (
      <ReactRouter>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            {Object.values(routes).map((route, index) => {
              if (route.authRequired) {
                return (
                  <PrivateRoute
                    key={route + index}
                    path={route.path}
                    exact={!!route.exact}
                    component={route.component}
                    isLoggedIn={route.isLoggedIn}
                    isAllowed={route.isAllowed}
                    redirectTo={route.redirectTo}
                  />
                );
              }
              return <NonLoginRoute 
                      key={index} 
                      path={route.path} 
                      exact={!!route.exact}
                      component={route.component} 
                      isLoggedIn={route.isLoggedIn}
                      redirectTo={route.redirectTo}  
                      />;
            })}
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </ReactRouter>
    );
  }
}

const mapStateToProps = state => ({
  isInitialized: state.userReducer.isInitialized,
  isLoggedIn: state.userReducer.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  initApp: () =>{ 
    loginActions.IHP_CHANNEL.onmessage = ({event,uid,data})=>{
      const {appContext,CHANNEL_EVENTS,signout,refreshSession,expirySession} = loginActions;
      console.log('event',event,uid,appContext.uid);
      switch(event){
        case CHANNEL_EVENTS.SIGN_IN: !appContext.user && window.location.reload();break;
        case CHANNEL_EVENTS.SIGN_OUT: appContext.user && dispatch(signout);break;
        case CHANNEL_EVENTS.SESSION_REFRESHED:appContext.user && dispatch(refreshSession(data.lat));break;
        case CHANNEL_EVENTS.SESSION_EXPIRED:appContext.user && dispatch(expirySession);break;
      }
    }
     
    dispatch(loginActions.initializeApplication);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRouter);
