export const useStyles = MUITheme => ({
  AppHeader:{
    height: 56,
    padding: '2px 24px !important',
    display: 'flex',
    alignItems:' center',
    justifyContent: 'space-between',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.1)',
    background: MUITheme.palette.background.white,
    position:"sticky",
    [MUITheme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
    }
  },
  toolBar:{
    width:"100%",
    justifyContent:"space-between",
    minHeight:"50px",
    [MUITheme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
    }
  },
  logoNavContainer:{    
    display: 'flex',
    alignItems: 'center',
    '& h1':{
      width: 280,
      margin: 0,
      lineHeight: 0,
      '& a':{
        display: 'inline-block',
        '& img':{
          maxHeight: 55
        }  
      }      
    },
    [MUITheme.breakpoints.down("sm")]: {
      justifyContent: 'center',
    }
  },
  dropDownUserActionsContainer:{
    display:'flex',
    [MUITheme.breakpoints.down("sm")]: {
      justifyContent: 'space-evenly',
    }
  },
  loggedInActionsContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  clinicSelectorContainer:{
    '& > div':{
      width: 172,
      border: '1px solid rgba(142,147,162,0.15)',
      borderRadius: 6,
      backgroundColor: MUITheme.palette.background.white,
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
      '&::before':{ display: 'none' },
      '& > div':{ 
        padding: '8px 19px !important',
        '&:focus':{ backgroundColor: '#fff' }
      },
      '& svg':{  
        paddingRight: 15,
        color: MUITheme.palette.text.blue.secondary 
      },
    },
    '&:focus':{ outline: 'none'},
    '&.MuiMenu-list':{ padding: 0 }
  },
  menuItemContainer:{ 
    '& li':{
      padding: '10px 15px',
      color: MUITheme.palette.text.primary,
      fontSize: 14,
      fontFamily: MUITheme.typography.fontFamily.secondary,
      '&:hover':{ 
        color: MUITheme.palette.text.blue.secondary,
        backgroundColor: MUITheme.palette.text.white.primary
      }
    }
  },
  disabledMenuItem:{ fontSize: '14px !important'},
  iconsContainer:{
    marginLeft: 24,
    display: 'flex',
    alignItems:' center'
  },
  NotificationsIcon:{
    marginRight: 23,
    color: MUITheme.palette.text.blue.secondary,
  },
  AccountCircleIcon:{
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: MUITheme.palette.text.bgBlue,
    borderRadius: 25,
    boxShadow: '0 0 10px 0 rgba(207,217,223,0.5)',
    fontSize: 37,
    '& svg':{ cursor: 'pointer' },
    '& img':{ cursor: 'pointer' }
  },
  sectionMobile: {
    display: 'flex',
    color: MUITheme.palette.text.primary,
    [MUITheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sectionDesktop: {
    display: 'none',
    [MUITheme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

});
