export const navbarItems = [
  {
    label: "Dashboards",
    type: "link",
    openInNewTab: false,
    activeLinks: ["/mydashboard", "/patienttrial"],
    to: "/mydashboard",
    icon: "/static/images/dashboard.svg"
  },
  {
    label: "Patient 360",
    type: "link",
    privilege: "PATIENT-360",
    openInNewTab: false,
    activeLinks: ["/patient360"],
    to: "/patient360",
    icon: "/static/images/patient-360.svg",
    isHidden: ({ clinicId, clinicDashboards, permissions }) =>
      !permissions.includes("PATIENT-360") ||
      (clinicDashboards.init &&
        !!clinicDashboards[clinicId] &&
        (!clinicDashboards[clinicId].category ||
          !clinicDashboards[clinicId].category.length)),
  },
  {
    label: "Smart Scheduler",
    type: "link",
    privilege: "SMART_SCHEDULER",
    openInNewTab: false,
    activeLinks: ["/smart-scheduler"],
    to: "/smart-scheduler",
    icon: "/static/images/smart-scheduler.svg",
  },
  {
    label: "AI Services",
    id: "AI_SERVICES",
    privilege: "AI_SERVICES",
    type: "select",
    activeLinks: ["/ai-services"],
    icon: "/static/images/ai-services.svg",
    services: [
      {
        label: "Speech To Text",
        privilege: "TOOLS_VADAAMI",
        to: "/ai-services/speech-to-text",
      },
      {
        label: "Co Pilot",
        privilege: "TOOLS_COPILOT",
        to: "/ai-services/co-pilot",
      },
      { label: "eFax", privilege: "TOOLS_EFAX", to: "/ai-services/e-fax" },
    ],
  },
  {
    label: "Smart Forms",
    type: "link",
    privilege: "SMART_FORM",
    openInNewTab: false,
    activeLinks: ["/smart-forms"],
    to: "/smart-forms",
    icon: "/static/images/smart-forms.svg",
  },
  {
    label: "Settings",
    id: "SETTINGS",
    type: "select",
    activeLinks: ["/settings"],
    icon: "/static/images/settings.svg",
    services: [
      {
        label: "Service Subscription",
        privilege: "SERVICE SUBSCRIPTION",
				icon:"/static/images/subscription.svg",
        to: "/settings",
      },
      {
        label: "Payers And Measures",
        privilege: "PAYER MEASURE",
				icon:"/static/images/clinic.svg",
        to: "/settings/payersandmeasures",
      },
      {
        label: "Clinic Management",
        privilege: "CLINIC MANAGEMENT",
				icon:"/static/images/clinic.svg",
        to: "/settings/clinicmanagement",
      },
      {
        label: "Customize App",
        privilege: "APP CUSTOMIZATION",
				icon:"/static/images/customize.svg",
        to: "/settings/customizeapp",
      },
      {
        label: "User Management",
        privilege: "USER MANAGEMENT",
				icon:"/static/images/users.svg",
        to: "/settings/usermanagement",
      },
      {
        label: "User Role Management",
        privilege: "ROLE MANAGEMENT",
				icon:"/static/images/user-roles.svg",
        to: "/settings/userrolemanagement",
      },
      { label: "Data Import", 
        privilege: "DATA", 
        to: "/settings/dataimport" ,
				icon:"/static/images/data-import.svg",
      },
      {
        label: "Scheduler",
        privilege: "SMART_SCHEDULER_ADMIN",
				icon:"/static/images/smart-scheduler.svg",
        to: "/settings/smartscheduler",
      },
      { label: "Help Center", to: "/settings/helpcenter",icon:"/static/images/help.svg" },
    ],
  },
];
