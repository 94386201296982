import { combineReducers } from "redux";
import loaderReducer from "./LoaderReducer";
import userReducer from "./UserReducer";
import errorReducer from "./ErrorReducer";
import leadGenReducer from "./LeadGenReducer";
import personalizedReducer from "./PersonalizedReducer";
import adtReducer from "./ADTReducer";
import clinicReducer from "./ClinicReducer";
import platformCoreReducer from './PlatformCoreReducer'
import payersAndMeasuresReducer from './PayersAndMeasuresReducer';
import helpCenterReducer from './HelpCenterReducer';
import patient360Reducer from './Patient360Reducer';
import patientTrialReducer from './PatientTrialReducer';
import smartSchedulerReducer from './SmartSchedulerReducer';
import nlpReducer from './NLPReducer';

const rootReducer = combineReducers({
    loaderReducer,
    userReducer, 
    errorReducer, 
    leadGenReducer,
    personalizedReducer,
    adtReducer,
    clinicReducer,
    platformCoreReducer,
    payersAndMeasuresReducer,
    helpCenterReducer,
    patient360Reducer,
    patientTrialReducer,
    nlpReducer,
    smartSchedulerReducer
});

export default rootReducer;
