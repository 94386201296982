import React, { Fragment } from "react";

import Footer from "../Footer";
import LeftMenuAndHeader from '../LeftMenuAndHeader/';

const WithHeaderAndFooter = (Component,opt={showLeftMenu:true,contentClass:'page-content'}) => {

  return props => (
      <Fragment>
        <LeftMenuAndHeader {...opt}>
          <Component {...props} />
        </LeftMenuAndHeader>
        <Footer />
      </Fragment>
  );

};

export default WithHeaderAndFooter;
