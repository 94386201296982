import { API } from "aws-amplify";

import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";
import { initializeAPIOptions } from "../../utility/API";
import { fetchAuthenticatedUser } from "./UserActions/loginActions";

import { errorActions, loaderActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const TRIAL_VIEW_LIST = "TRIAL_VIEW_LIST";
export const FILTER_META_DATA = "FILTER_META_DATA";
export const FILTER_VALUES = "FILTER_VALUES";
export const PATIENT_TRIAL_REPORT = "PATIENT_TRIAL_REPORT";
export const EXPORT_FILE_CONTENT = "EXPORT_FILE_CONTENT";


const getOptionsMap = (options,codeKey,labelKey) => options.map(opt=>({code:opt[codeKey],label:opt[labelKey]}));
const setPayload = (type,payload) => ({
    type,payload
});

const updateErrorCode = err => dispatch => {
    dispatch(errorActions.resetCustomAuthenticationErrorCode(err));
};

const fetchViewListApi = (selectedClinicId) => async dispatch => {
     const { token } = await dispatch(fetchAuthenticatedUser());

    const additionalHeaders = {
        "clinic-id": selectedClinicId,
    }

    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.VIEW_DETAILS;
    const apiOptions = initializeAPIOptions(token, undefined, undefined, additionalHeaders);

    const data = await API.get(apiName, apiPath, apiOptions);
    return data.data; 
    //return viewList;
}

const fetchFilterMetadataApi = (selectedClinicId) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

 const additionalHeaders = {
     "clinic-id": selectedClinicId,
 }

 const apiName = APIEndpoints.NHCCLINICAPI.name;
 const apiPath = APIPaths.PATIENT_FILTER_META_DATA;
 const apiOptions = initializeAPIOptions(token, undefined, undefined, additionalHeaders);

 const data = await API.get(apiName, apiPath, apiOptions);
 return data; 
 //return filterMetadata;
}

const fetchFilterValuesApi = (selectedClinicId,field) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

 const additionalHeaders = {
     "clinic-id": selectedClinicId,
 }

 const apiName = APIEndpoints.NHCCLINICAPI.name;
 const apiPath = APIPaths.PATIENT_FILTER_LOOK_UP_VALUES;
 const apiOptions = initializeAPIOptions(token, undefined, {lookup_type:field}, additionalHeaders);

 const data = await API.get(apiName, apiPath, apiOptions);
 return data; 
 //return filterValues[field] || [];
}

const fetchPatientTrialReportApi = (selectedClinicId,payload) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

 const additionalHeaders = {
     "clinic-id": selectedClinicId,
 }

 const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
 const apiPath = APIPaths.PATIENT_TRIAL_REPORT;
 const apiOptions = initializeAPIOptions(token, payload, undefined, additionalHeaders);

 const data = await API.post(apiName, apiPath, apiOptions);
 return data.data; 
 //return patientReport;
}

const saveViewApi = (selectedClinicId, filters, viewName) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

  const additionalHeaders = {
      "clinic-id": selectedClinicId,
  }
  const body = {
      "view_name": viewName,
      filters
  }
  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.VIEW_DETAILS;
  const apiOptions = initializeAPIOptions(token, body, undefined, additionalHeaders);

  const data = await API.post(apiName, apiPath, apiOptions);
  return data.data;
}

const updateViewApi = (selectedClinicId,viewId, filters, viewName) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

  const additionalHeaders = {
      "clinic-id": selectedClinicId,
  }
  const body = {
      "view_name": viewName,
      "view_id":viewId,
      filters
  }
  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.UPDATE_VIEW_DETAILS(viewId);
  const apiOptions = initializeAPIOptions(token, body, undefined, additionalHeaders);

  return await API.put(apiName, apiPath, apiOptions);
}

const deleteViewApi = (selectedClinicId, id) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

  const additionalHeaders = {
      "clinic-id": selectedClinicId,
  }
  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.DELETE_VIEW(id);
  const apiOptions = initializeAPIOptions(token, undefined, undefined, additionalHeaders);

  return await API.put(apiName, apiPath, apiOptions);
}

const exportViewApi = (selectedClinicId, filter,report_type) => async dispatch => {
  const { token } = await dispatch(fetchAuthenticatedUser());

  const additionalHeaders = {
      "clinic-id": selectedClinicId,
  }
  const body = {
    filter,
    report_type
  }

  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.PATIENT_TRIAL_REPORT_EXPORT;
  const apiOptions = initializeAPIOptions(token, body, null, additionalHeaders);

  return await API.post(apiName, apiPath, apiOptions);
}

export const exportView = (selectedClinicId, filter,report_type) => async dispatch => {
  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.EXPORT_VIEW));
      //dispatch(setPayload(EXPORT_FILE_CONTENT,null));
      const response = await dispatch(exportViewApi(selectedClinicId, filter,report_type));
      //dispatch(setPayload(EXPORT_FILE_CONTENT,response.data));
      return response.data;
  } catch (error) {
      throw error;
  }
  finally{
      dispatch(loaderActions.stopAppLoader());
  }
}

export const deleteView = (selectedClinicId, id) => async dispatch => {
  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.DELETE_VIEW));
      await dispatch(deleteViewApi(id, selectedClinicId));
      dispatch(loaderActions.stopAppLoader());
  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      return
  }
}

export const saveView = (selectedClinicId, filters, viewName) => async dispatch => {
  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.SAVE_VIEW));
      const response = await dispatch(saveViewApi(selectedClinicId, filters, viewName));
      return response;
  } catch (error) {
      throw error;
  }
  finally{
    dispatch(loaderActions.stopAppLoader());
  }
}

export const updateView = (selectedClinicId,viewId, filters, viewName) => async dispatch => {
  try {
      //dispatch(loaderActions.startAppLoader(LoaderContent.SAVE_VIEW));
      await dispatch(updateViewApi(selectedClinicId,viewId, filters, viewName));
      //dispatch(loaderActions.stopAppLoader());
  } catch (error) {
      //dispatch(loaderActions.stopAppLoader());
      // throw error;
      return
  }
}

export const fetchViewList = (selectedClinicId) => async dispatch => {

  try {
      //dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_TRIAL_DETAILS));
      //dispatch(setPayload(TRIAL_VIEW_LIST,[]));
      if (selectedClinicId) {
          const response = await dispatch(fetchViewListApi(selectedClinicId));
          dispatch(setPayload(TRIAL_VIEW_LIST,response));            
      }
  } catch (error) {
      dispatch(setPayload(TRIAL_VIEW_LIST,[]));
  }
  finally{
      //dispatch(loaderActions.stopAppLoader());
  }
}

export const fetchViewDetails = (selectedClinicId) => async dispatch => {

  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_TRIAL_DETAILS));
      dispatch(setPayload(TRIAL_VIEW_LIST,[]));
      if (selectedClinicId) {
          const response = await dispatch(fetchViewListApi(selectedClinicId));
          dispatch(setPayload(TRIAL_VIEW_LIST,response));
          dispatch(fetchFilterMetadataApi(selectedClinicId)).then(res=>{
            dispatch(setPayload(FILTER_META_DATA,res.data));
          })
            
      }
  } catch (error) {
      dispatch(setPayload(TRIAL_VIEW_LIST,[]));
  }
  finally{
      dispatch(loaderActions.stopAppLoader());
  }
}

export const fetchFilterMetadata = (selectedClinicId) => async dispatch => {

    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_TRIAL_DETAILS));
        if (selectedClinicId) {
            const response = await dispatch(fetchFilterMetadataApi(selectedClinicId));
            dispatch(setPayload(FILTER_META_DATA,response));
        }
    } catch (error) {
        dispatch(setPayload(FILTER_META_DATA,{}));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchFilterValues = (selectedClinicId,field) => async dispatch => {

  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.GET_FILTER_VALUES));
      if (selectedClinicId) {
          const response = await dispatch(fetchFilterValuesApi(selectedClinicId,field));
          //dispatch(setPayload(FILTER_VALUES,{[field]:response}));
          return getOptionsMap(response.data,'lookup_code','lookup_value');
      }
  } catch (error) {
      //dispatch(setPayload(FILTER_VALUES,{[field]:[]}));
      return [];
  }
  finally{
      dispatch(loaderActions.stopAppLoader());
  }
}

export const fetchPatientTrialReport = (selectedClinicId,payload) => async dispatch => {

  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_TRIAL_DETAILS));
      if (selectedClinicId) {
          const response = await dispatch(fetchPatientTrialReportApi(selectedClinicId,payload));
          dispatch(setPayload(PATIENT_TRIAL_REPORT,response));
      }
  } catch (error) {
      dispatch(setPayload(PATIENT_TRIAL_REPORT,{}));
  }
  finally{
      dispatch(loaderActions.stopAppLoader());
  }
}

