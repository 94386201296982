import { userActions } from "../actionCreators";

const initialState = {
  entity: "",
  isInitialized: false,
  isLoggedIn: false,
  isForcePasswordChange: false,
  challenge:null,
  email: undefined,
  isEmailVerified: false,
  jwt: {
    exp: "",
  },
  inviteeStatus: "",
  inviteCode: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  userPreferences: {},
  userNotConfirmed:false,
  sessionData:{},
  userClinicsAndRoles: {},
  profilePicURL: "",
  
  userPermissionsBasedOnClinic: {}

};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.loginActions.SET_USER_LOGGED_IN: {
      return { ...state, isLoggedIn: action.payload };
    }
    case userActions.loginActions.SET_USER_FORCE_PASSWORD_CHANGE: {
      return { ...state, isForcePasswordChange: action.payload };
    }
    case userActions.loginActions.SET_USER_CHALLENGE: {
      return { ...state, challenge: action.payload };
    }
    case userActions.loginActions.RESET_LOGIN: {
      return { ...state, challenge: null, userNotConfirmed:false,sessionData:{}};
    }
    case userActions.loginActions.SET_USER_EMAIL: {
      return { ...state, email: action.payload };
    }
    case userActions.loginActions.SET_USER_EMAIL_VERIFIED: {
      return { ...state, isEmailVerified: action.payload };
    }
    case userActions.loginActions.SET_APP_INITIALIZED: {
      return { ...state, isInitialized: action.payload };
    }
    case userActions.loginActions.RESET_USER_ON_SIGNOUT: {
      return { ...initialState, isInitialized: true,...action.payload };
    }
    case userActions.loginActions.SET_JWT_EXP: {
      return { ...state, jwt: { ...state.jwt, exp: action.payload } };
    }
    case userActions.loginActions.USER_SESSION_UPDATED: {
      return { ...state, sessionData: { ...state.sessionData, ...action.payload } };
    }
    case userActions.loginActions.SET_USER_ATTRIBUTES: {
      return {...state, ...action.payload}
    }
    case userActions.profilePicActions.UPDATE_PROFILE_PIC:{
      return {...state, profilePicURL : action.payload }
    }
    case userActions.profilePicActions.SET_USER_PERMISSIONS_BASED_ON_CLINIC:{
      return {...state, userPermissionsBasedOnClinic : action.payload}
    }
    default:
      return state;
  }
};

export default userReducer;


// *****************************************
// OLD Code To Be Deleted
// *****************************************

/*

import { userActions } from "../actionCreators";
import { RESET_LOGIN_ERROR } from "../actionCreators/UserActions";

const InitialUserDetails = {
  login: {
    isLoggedIn: false,
    error: undefined,
    loading: false,
  },
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  address: "",
  isInitialized: false,
  isEmailVerified: false,
  emailAlerts: false,
  isTermsAccepted: true,
};

const userReducer = (state = InitialUserDetails, action) => {
  switch (action.type) {
    case userActions.SET_USER_ATTRIBUTES: {
      return {...state, ...action.payload}
    }
    case userActions.APP_INITIALIZATION_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case userActions.SET_USER_DETAILS: {
      return {
        ...state,
        login: {
          ...state.login,
          ...action.payload.login,
        },
        ...action.payload,
      };
    }
    case userActions.LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        login: {
          ...state.login,
          error: undefined,
          loading: false,
          ...action.payload.login,
        },
      };
    }
    case userActions.LOGIN_LOADING: {
      return {
        ...state,
        login: {
          ...state.login,
          error: undefined,
          loading: true,
        },
      };
    }
    case userActions.LOGIN_ERROR: {
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          isLoggedIn: false,
          ...action.payload.login,
        },
      };
    }
    case RESET_LOGIN_ERROR: {
      return { ...state, login: { ...state.login, error: undefined } };
    }
    case userActions.UPDATE_LOGIN_ERROR: {
      return { ...state, login: { ...state.login, error: action.payload } };
    }
    case userActions.SIGN_OUT: {
      return {
        ...state,
        login: {
          ...state.login,
          ...action.payload.login,
        },
      };
    }
    case userActions.UPDATE_EMAIL: {
      return { ...state, ...action.payload };
    }
    case userActions.UPDATE_EMAIL_VERIFIED: {
      return { ...state, isEmailVerified: action.payload.isEmailVerified };
    }
    case userActions.UPDATE_EMAIL_ALERTS_SUBSCRIPTION: {
      return { ...state, emailAlerts: action.payload };
    }
    case userActions.UPDATE_IS_TERMS_ACCEPTED: {
      return { ...state, isTermsAccepted: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
*/