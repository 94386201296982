import React from "react";

import Loader from "../common/Loader";
import { useSelector } from "react-redux";

const GlobalLoader = () => {
  const { isLoading, title, content,loader } = useSelector(state => state.loaderReducer.app);

  return <Loader isLoading={isLoading} title={title} content={content} loader={loader}/>;
};

export default GlobalLoader;
