import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(MUITheme => ({
AdminUser:{
    flex:"1",
    padding: 24,
    position: 'relative',
    '&.p-0':{
      padding:0
    },
    '&.p-1':{
      padding:1
    },
    '&.p-4':{
      padding:4
    }
  },
  AdminWraper:{
    width:"100%",
    background: MUITheme.palette.background.primary,
    paddingTop:"0px",
    display:"flex",
    // flexDirection: 'column',
    minHeight:"100vh",
    [MUITheme.breakpoints.down("sm")]: { 
      position:"relative",
      
    },
  },
  activeClass:{
    position:"relative",
    width:"100%",
    "&::before":{
      "@media (max-width:600px)": {
        content:'""',
        position:"fixed",
        backgroundColor:"rgba(109,201,234,0.4)",
        top:"0",
        bottom:"0",
        left:"0",
        right:"0",
        zIndex:"99",
       
      },
    },
  },
  leftmenuDashboardContainer:{ 
    display: 'flex',
  },
  dashboardsMainParent:{ minHeight: 'calc(100vh - 195px)'},
  footerText:{
    margin: "140px 0 30px",
    background: MUITheme.palette.background.primary,
    textAlign: 'center',
    '@media(max-width:820px)':{ padding: '25px auto'}
  }
}));