import React from "react";

import { withStyles } from "@material-ui/styles";

import VersionDetail from "./VersionDetail";
import logo from "../../assets/images/logo.png"; 

import { useStyles } from "./styles";

const Footer = React.memo(({ classes} ) => {
	return (
		<div className={classes.footerContainer}>
			{/* <VersionDetail /> */}
			<p className={classes.footerDescription}>Copyright {new Date().getFullYear()} iNaira Healthcare. All Rights Reserved.</p>
			<div className={classes.footerLogo}>
				<span>Powered by</span>
				<h1>
					<a href="https://www.inairahealth.com" title="iNaira Healthcare Technologies" target="_blank" rel="noopener noreferrer">
						<img src={logo} alt="iNaira Healthcare Technologies" />
					</a>
				</h1>
			</div>
		</div>
	)
})

export default withStyles(useStyles)(Footer);
