import React, {memo} from "react";
import { withStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
//import PersonIcon from "@material-ui/icons/Person";
import Avatar from "@material-ui/core/Avatar";

import { useStyles } from "./styles";

const UserProfileCard = memo(({ classes, firstName, profilePicURL, onClose }) => {
  console.log('profilePicURL',profilePicURL);
  return (
    <div className={classes.Userdetails}>
      <Avatar className={classes.avatar} src={profilePicURL || '/static/images/avatar-default.svg'}/>
      {/* <PersonIcon /> */}
      <div>
        <h4>{firstName}</h4>
      </div>
      {onClose && <CloseIcon className={classes.closeIcon} onClick={onClose} />}
    </div>
  );
});

UserProfileCard.propTypes = {
  firstName: PropTypes.string,
  onClose: PropTypes.func,
};

export default withStyles(useStyles)(UserProfileCard);
